import { PolicyStatus } from 'policies/domain/policy-status';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';

enum PolicyStatusColors {
  Success = '#1fc881',
  Warning = '#ffab00',
  Danger = '#d50001',
}
export const policyStatusColorMapping = (
  status: PolicyStatus,
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null,
) => {
  const policyStatusColorMapping = {
    [PolicyStatus.Active]: siteConfig?.styles.colors.policyStatusActive || PolicyStatusColors.Success,
    [PolicyStatus.PendingInitialPayment]: siteConfig?.styles.colors.policyStatusPending || PolicyStatusColors.Warning,
    [PolicyStatus.Pending]: siteConfig?.styles.colors.policyStatusPending || PolicyStatusColors.Warning,
    [PolicyStatus.Cancelled]: siteConfig?.styles.colors.policyStatusCancelled || PolicyStatusColors.Danger,
    [PolicyStatus.Expired]: siteConfig?.styles.colors.policyStatusExpired || PolicyStatusColors.Warning,
    [PolicyStatus.NotTakenUp]: siteConfig?.styles.colors.policyStatusNotTakenUp || PolicyStatusColors.Danger,
    [PolicyStatus.Lapsed]: siteConfig?.styles.colors.policyStatusLapsed || PolicyStatusColors.Danger,
  };

  return policyStatusColorMapping[status];
};

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './rootstrap/scss/index.scss';
import { PolicyIssuingScene } from './policy-issuing/policy-issuing-scene';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { ConfirmationScene } from './policy-issuing/confirmation/confirmation-scene';
import styled from 'styled-components';
import { useDraftDefinition, usePrefillValues, useEnvironment, useAuth, AuthTypes } from './shared/api';
import { usePromise } from './shared/hooks/promise';
import { getOrganization } from './organizations/actions/get-organization';
import FooterImage from './assets/Powered-by-root.svg';
import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/questrial/400.css';
import '@fontsource/league-spartan/100.css';
import '@fontsource/league-spartan/300.css';
import '@fontsource/league-spartan/400.css';
import '@fontsource/league-spartan/500.css';
import '@fontsource/league-spartan/700.css';
import '@fontsource/league-spartan/900.css';
import { Environment } from './models/environment';
import { SiteConfigContext } from './style-context';
import { getProductModule } from './product-modules/actions/get-product-module';
import { EmbeddedConfigSection, getFontFamily, ProductModuleDefinitionEmbeddedConfig } from './site-config';
import { ProductModule } from './product-modules/domain/product-module';
import { PolicyFlowSiteConfigIsLoading } from './policy-issuing/page-mount/site-config-is-loading';
import { createEmbedSession } from './embed-sessions/actions/create-embed-session';
import { EmbedSessionContext } from './rootstrap/components/tabs/hooks/embed-session-context';
import { LandingPage } from './landing-page/landing-page';
import { ConsentModal } from './landing-page/consent-modal';
import { PolicyManagementScene } from './policy-management/policy-management-scene';
import { globalStyles } from './rootstrap/global-styles';
import { EmbedParams, EmbedParamsContext } from './shared/embed-params-context';
import { MixpanelProvider } from './context/mix-pannel-context';
import { getEmbedSessionPrefillData } from './embed-sessions/actions/get-embed-session-prefill-data';
import { createEmbedSessionPrefillData } from './embed-sessions/actions/create-embed-session-prefill-data';
import { useQueryParams } from './shared/hooks/url';
import { EmbedSessionPrefillValuesObject } from './embed-sessions/domain/embed-session-prefill-data';
import { PrefillValues } from './shared/domain/prefill-values';
import { Helmet } from 'react-helmet';

export function App() {
  const auth = useAuth();
  const { product_module_key: productModuleKey, organization_id: organizationId } = useParams<{
    product_module_key: string | undefined;
    organization_id: string | undefined;
  }>();
  const targetDraftProductModuleDefinition = useDraftDefinition();
  const { queryParams, setQueryParams } = useQueryParams<{ embed_session_id?: string }>();
  const urlPrefillValues = usePrefillValues();

  const [prefillValues, setPrefillValues] = useState(usePrefillValues());

  const [siteConfig, setSiteConfig] = useState<ProductModuleDefinitionEmbeddedConfig>();
  const environment = useEnvironment();

  const [embedParams, setEmbedParams] = useState<EmbedParams>({
    environment,
    organizationId: '',
    productModuleKey: '',
    auth,
    organizationName: '',
    isUnauthenticated: undefined,
  });

  const [embedSessionId, setEmbedSessionId] = useState<string>();
  const [loggedImpressions, setLoggedImpressions] = useState<string[]>([]);

  const isProduction = environment === Environment.Production;
  const [productModule, setProductModule] = useState<ProductModule>();
  const [consentModalIsOpen, setConsentModalIsOpen] = React.useState<boolean>(true);

  const {
    result: organization,
    isLoading: isLoadingOrganization,
    error: productModuleError,
  } = usePromise(async () => {
    setEmbedParams({ ...embedParams });
    const organization = await getOrganization({
      organizationId,
      auth,
      environment,
    });

    const productModuleResult = await getProductModule({
      auth,
      organizationId: organization.organizationId,
      environment,
      targetDraftProductModuleDefinition,
      productModuleKey,
    });

    setSiteConfig(productModuleResult.productModuleDefinition?.productModuleEmbedConfig);
    setProductModule(productModuleResult);

    const enableSessionPersistence =
      productModuleResult.productModuleDefinition?.productModuleEmbedConfig?.settings.enableSessionPersistence;

    let embedSessionId = queryParams.embed_session_id;

    if (!productModuleResult.isUnauthenticated && enableSessionPersistence && !embedSessionId) {
      const embedSession = await createEmbedSession({
        organizationId: organization.organizationId,
        productModuleId: productModuleResult.productModuleId,
        environment,
        auth,
      });

      embedSessionId = embedSession.embedSessionId;
    }

    if (enableSessionPersistence && embedSessionId) {
      try {
        const { prefillData: embedSessionPrefillValues } = await getEmbedSessionPrefillData({
          auth,
          embedSessionId,
          environment,
          organizationId: organization.organizationId,
        });

        await createEmbedSessionPrefillData({
          auth,
          embedSessionId,
          environment,
          organizationId: organization.organizationId,
          embedSessionPrefillData: embedSessionPrefillValues,
        });

        setPrefillValues(
          getPrefillValues({
            prefillValues: urlPrefillValues,
            sessionPrefillValues: embedSessionPrefillValues,
          }),
        );
      } catch (error) {
        console.error('Failing to load embed session prefill data', error);
      }
    }

    if (enableSessionPersistence) {
      setQueryParams({ ...queryParams, embed_session_id: embedSessionId });
    }
    setEmbedSessionId(embedSessionId);

    setEmbedParams({
      auth,
      environment,
      organizationId: organization.organizationId,
      productModuleKey: productModuleResult.key,
      organizationName: organization.name,
      isUnauthenticated: productModuleResult.isUnauthenticated,
    });

    return organization;
  }, []);

  const isOrganizationEnabledForProduction = !!organization?.reviewed;
  const header = siteConfig?.header;
  const titleUrl = header?.images.titleUrl;
  const titleImageEnabled = header?.displayOptionalSections.titleImage;
  const policyIssuingIFrameUrl = siteConfig?.policyIssuingIFrameUrl;
  const basePath = auth.type === AuthTypes.JWT ? '/organization/:organization_id' : '/:product_module_key';
  const consentModalEnabled = siteConfig?.quote.displayOptionalSections.consentDisclaimer;

  const displayIssuingFlow = productModule && siteConfig && !policyIssuingIFrameUrl && !isLoadingOrganization;
  const displayIssuingFlowLoading = (!productModule || !siteConfig) && !policyIssuingIFrameUrl && isLoadingOrganization;
  const displayConsentModal = consentModalEnabled && consentModalIsOpen;
  const displayExternalIssuingFlow = siteConfig && policyIssuingIFrameUrl;
  const displayPolicyManagementScene = productModule && siteConfig && embedParams.organizationId;
  const displayPolicyManagementIsLoadingScene = !productModule || !siteConfig || !embedParams.organizationId;
  const displayTitleImage = siteConfig && titleImageEnabled && titleUrl;
  const fetchifyAutoCompleteEnabled =
    siteConfig?.[EmbeddedConfigSection.PersonalDetails].displayOptionalSections.fetchifyAutoComplete;

  return (
    <EmbedParamsContext.Provider value={{ embedParams, setEmbedParams }}>
      <SiteConfigContext.Provider value={{ siteConfig: siteConfig || null, setSiteConfig }}>
        {fetchifyAutoCompleteEnabled && (
          <Helmet>
            <script src='https://cc-cdn.com/generic/scripts/v1/cc_c2a.min.js' />
          </Helmet>
        )}
        <EmbedSessionContext.Provider
          value={{
            embedSessionId: embedSessionId || undefined,
            setEmbedSessionId,
            loggedImpressions,
            setLoggedImpressions,
          }}
        >
          <MixpanelProvider>
            <div
              style={{
                height: '100vh',
                fontFamily: siteConfig
                  ? getFontFamily({ siteConfig, fontFamily: 'body' })
                  : globalStyles.fontFamily.body,
              }}
            >
              {displayTitleImage && (
                <HeaderStyle siteConfig={siteConfig}>
                  <div>
                    <img height={20} src={titleUrl} alt='' />
                  </div>
                </HeaderStyle>
              )}
              <Router>
                <Switch>
                  <Route path={`${basePath}/confirmation`}>
                    <ConfirmationScene productModule={productModule} />
                  </Route>
                  <Route path={`${basePath}/issue-policy`}>
                    {displayIssuingFlow && (
                      <>
                        {displayConsentModal && (
                          <ConsentModal toggle={() => setConsentModalIsOpen(!consentModalIsOpen)} />
                        )}
                        <PolicyIssuingScene prefillValues={prefillValues} productModule={productModule} />
                      </>
                    )}
                    {displayIssuingFlowLoading && <PolicyFlowSiteConfigIsLoading />}
                  </Route>
                  <Route path={`${basePath}/manage`}>
                    {displayPolicyManagementScene && <PolicyManagementScene productModule={productModule} />}
                    {displayPolicyManagementIsLoadingScene && <PolicyFlowSiteConfigIsLoading />}
                  </Route>
                  <Route path={basePath}>
                    <LandingPage
                      productModule={productModule}
                      isLoading={isLoadingOrganization}
                      error={productModuleError}
                    />
                  </Route>
                </Switch>
              </Router>
            </div>
          </MixpanelProvider>
        </EmbedSessionContext.Provider>
      </SiteConfigContext.Provider>
    </EmbedParamsContext.Provider>
  );
}

const isUrlPrefill = (value: any) => value && Object.keys(value).length !== 0;

const getPrefillValues = (params: {
  prefillValues: PrefillValues;
  sessionPrefillValues: EmbedSessionPrefillValuesObject | undefined;
}): PrefillValues => {
  const { prefillValues, sessionPrefillValues } = params;
  return {
    screeningQuestions: sessionPrefillValues?.screeningQuestions,
    quote: isUrlPrefill(prefillValues.quote) ? prefillValues.quote : sessionPrefillValues?.quote,
    prePersonalDetailsConsent: sessionPrefillValues?.prePersonalDetailsConsent,
    personalDetails: isUrlPrefill(prefillValues.personalDetails)
      ? prefillValues.personalDetails
      : sessionPrefillValues?.personalDetails,
    application: isUrlPrefill(prefillValues.application)
      ? prefillValues.application
      : sessionPrefillValues?.application,
    prePaymentDetailsConsent: sessionPrefillValues?.prePaymentDetailsConsent,
    payment: isUrlPrefill(prefillValues.payment) ? prefillValues.payment : sessionPrefillValues?.payment,
  };
};

export enum EmbedFlowType {
  PolicyIssuing = 'policyIssuing',
  PolicyManagement = 'policyManagement',
}

const HeaderStyle = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  div {
    height: 46px;
    position: absolute;
    align-items: center;
    width: 100%;
    justify-content: center;
    display: flex;
    background: ${({ siteConfig }) => siteConfig?.styles.colors.backgroundHighlight};
    position: absolute;
  }
`;

export const FloatingFooter = () => (
  <FloatingFooterStyle>
    <img id='powered-by' style={{ maxHeight: 21 }} src={FooterImage} alt='' />
  </FloatingFooterStyle>
);

const FloatingFooterStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 4px 4px;
  border-radius: 5px;

  img {
    max-height: 21px;
  }
`;

import React from 'react';
import { ProductModuleEmbedConfigSupportType } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { Alert } from '../alerts/alert';
import { UsePromiseError } from 'shared/hooks/promise';
import { sentenceCase } from 'sentence-case';

export const ErrorAlert = (params: { error: Error | undefined; title?: string; displayFieldError?: boolean }) => {
  const { siteConfig } = useSiteConfigContext();
  const supportType = siteConfig?.settings.supportType;

  const supportLinkText =
    (supportType === ProductModuleEmbedConfigSupportType.Email
      ? siteConfig?.settings.supportEmail
      : siteConfig?.settings.supportUrl?.label) || '';

  const supportLink =
    (supportType === ProductModuleEmbedConfigSupportType.Email
      ? `mailto:${siteConfig?.settings.supportEmail}`
      : siteConfig?.settings.supportUrl?.url) || '';
  const { error, title } = params;
  const displayFieldError = params.displayFieldError === true;

  if (!error) {
    return <></>;
  }

  return (
    <Alert
      variant='danger'
      title={title || 'Error'}
      body={
        <>
          <p>{getErrorMessage({ displayFieldError, error })}</p>
          <p>
            {`Please try again or ${supportType === ProductModuleEmbedConfigSupportType.Email ? 'contact' : 'visit'}`}
            &nbsp;
            <a href={supportLink} target='_blank' rel='noreferrer'>
              {supportLinkText}
            </a>
            &nbsp;if the problem persists.
          </p>
        </>
      }
    />
  );
};

const getErrorMessage = (params: { displayFieldError: boolean; error: UsePromiseError }) => {
  const { displayFieldError, error } = params;

  if (displayFieldError && error.fieldErrors && error.fieldErrors.length > 0) {
    // eslint-disable-next-line no-useless-escape
    const path = error.fieldErrors[0].path.replace(/[_\.]/g, ' ');
    const { message } = error.fieldErrors[0];
    // eslint-disable-next-line no-useless-escape
    const cleanedMessage = message.replace(/\".*?\"/g, '');

    return sentenceCase(`${path} ${cleanedMessage}`);
  }
  return error.message;
};

import { ProductModuleDefinitionAlterationHook } from 'alteration-hooks/domain/product-module-definition-alteration-hooks';
import { Policy } from 'policies/domain/policy';
import { PolicyStatus } from 'policies/domain/policy-status';
import { ManagementView, SetManagementViewParams } from 'policy-management/policy-management-scene';
import { ProductModule } from 'product-modules/domain/product-module';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { usePolicyManagementUrl } from 'shared/api';
import { useSiteConfigContext } from 'style-context';

interface AlterationHooksListViewParams {
  productModule: ProductModule | undefined;
  alterationHooks: ProductModuleDefinitionAlterationHook[];
  policy: Policy | undefined;
  setView: (view: SetManagementViewParams) => void;
}

export const AlterationHooksListView = (params: AlterationHooksListViewParams) => {
  const { policy, alterationHooks, setView } = params;
  const history = useHistory();
  const { siteConfig } = useSiteConfigContext();
  const alterationHookUrl = usePolicyManagementUrl({ view: ManagementView.Alterations, policyId: policy?.policyId });

  const alterationHooksDisplayOptions = siteConfig?.management?.policyDetails.displayOptionalSections.alterationHooks;

  const displayedAlterationHooks = alterationHooks?.filter((hook) => {
    const displayOptions = alterationHooksDisplayOptions?.find((option) => option.key === hook.key);
    if (!displayOptions) {
      return true;
    }
    return displayOptions.enabled;
  });

  return (
    <FormWrapperStyle>
      {displayedAlterationHooks.map(({ name, key }) => {
        return (
          <SuccessButton
            id={`alteration-hook-button=${key}`}
            key={key}
            outline
            disabled={policy?.status === PolicyStatus.Cancelled}
            siteConfig={siteConfig}
            fitContentHeight
            onClick={() => {
              history.push(`${alterationHookUrl}&alteration_hook_key=${key}&alteration_hook_name=${name}`);
              setView({
                view: ManagementView.Alterations,
                query: [
                  {
                    key: 'alteration_hook_key',
                    value: key,
                  },
                  {
                    key: 'alteration_hook_name',
                    value: name,
                  },
                ],
              });
            }}
            fullWidth={true}
          >
            <span>{name}</span>
          </SuccessButton>
        );
      })}
    </FormWrapperStyle>
  );
};
